"use strict";

var $ = require("jquery");
var Ar = require("./lib/ajax");
var config = require("./lib/config");
var modal = require("./lib/modal");
var common = require("./lib/common");
var Loader = require("./lib/loader");

require("modernizr");
require("foundation");
require("lettering");
require("textillate");

$(document).foundation();

$(".homepage-panel-text span").textillate({
  "loop": true,
  "minDisplayTime": 8000,
  "in": {
    "effect": "tada",
    "reverse": true
  },
  "out": {
    "effect": "tada",
    "sync": true
  }
});
$("#at-title").textillate({
  "loop": false,
  "type": "char",
  "in": {
    "effect": "fadeIn",
    "shuffle": true
  }
});
$("#at-content").textillate({
  "loop": true,
  "type": "word",
  "minDisplayTime": 3000,
  "in": {
    "effect": "fadeIn"
  },
  "out": {
    "delayScale": 0.5,
    "effect": "fadeOut"
  }
});
$("#at-content").on("click", function(e) {
  e.preventDefault();
  var textObject = $("#at-content span").first();
  if (textObject.attr("aria-label").lastIndexOf("CGM", 0) === 0) {
    window.location.href = config.paths.server + "applications#app-cgm";
  } else if (textObject.attr("aria-label").lastIndexOf("Qfactor", 0) === 0) {
    window.location.href = config.paths.server + "applications#app-qfactor";
  } else if (textObject.attr("aria-label").lastIndexOf("Strong", 0) === 0) {
    window.location.href = config.paths.server + "applications#app-database";
  } else {
    window.location.href = config.paths.server + "applications";
  }
});
$("#at-content").on("inAnimationBegin.tlt", function() {
  setTimeout(function() {
    var textObject = $("#at-content span").first();
    var breakPoint;
    if (textObject.attr("aria-label").lastIndexOf("CGM", 0) === 0) {
      breakPoint = 2;
    } else if (textObject.attr("aria-label").lastIndexOf("Qfactor", 0) === 0) {
      breakPoint = 2;
    } else if (textObject.attr("aria-label").lastIndexOf("Strong", 0) === 0) {
      breakPoint = 4;
    }
    textObject.find(".word" + breakPoint).after("<br />");
    textObject.find("span[class^='word']:lt(" + breakPoint + ")").css("font-size", "30px");
    textObject.find("span[class^='word']:lt(" + breakPoint + ")").css("color", "#78a22f");
    textObject.find("span[class^='word']:gt(" + (breakPoint - 1) + ")").css("font-size", "14px");
  }, 10);
});

function updateSession() {
  var ajax = new Ar();

  ajax.sendPost(config.paths.server + "login/ajaxSessionUpdate", null, {
    "success": function() {
      setTimeout(function() {
        updateSession();
      }, 500000);
    },
    "failure": function() {
      console.error("f");
    }
  }, 0);
}
updateSession();

// This event should be moved to separate .js file
$("#delete-api-key").on("click", function(e) {
  e.preventDefault();
  var gotoLink = $(this).attr("href");
  var m = new modal({
    "template": "confirm",
    "message": "If you delete your API key, all applications using this key will no longer have access to SMART strong ground motion database.<br/>Delete anyway?",
    "cb": function() {
      window.location.href = gotoLink;
    }
  });
  m.render();
});

$("#form-smartapps-registration").submit(function() {
  var button = $("#btn-smartapps-register");
  var guid = common.giveMeGUID();
  var l = new Loader({"selector": "#" + guid});

  button.html("<span id='" + guid + "'></span>");
  l.render();
});

$("#expand-main-menu").on("click", function() {
  $(".main-menu").toggleClass("responsive");
  $(".header_title").toggleClass("float_l");
});
