"use strict";


var domify = require("domify");

function Modal(opts) {
  if (!(this instanceof Modal)) { return new Modal(opts); }

  this.opts = opts || {};
  this.opts.template = typeof this.opts.template === "string" ? this.opts.template : "";
  switch (this.opts.template) {
    case "bulkMailSent" :
      this.createBulkMailSent();
      break;
    case "qf_calcStart" :
      this.createQfCalcStart();
      break;
    case "qf_calcComplete" :
      this.createQfCalcComplete();
      break;
    case "cgm_calcStart" :
      this.createCgmCalcStart();
      break;
    case "cgm_calcComplete" :
      this.createCgmCalcComplete();
      break;
    case "cgm_downloadGm" :
      this.createCgmDownloadGm();
      break;
    case "cgm_downloadData" :
      this.createCgmDownloadData();
      break;
    case "confirm" :
      this.createConfirm();
      break;
    case "alert" :
      this.createAlert();
      break;
    default:
      console.log("Modal template not found");
  }
}

// Create modal from confirm template and append it to body
Modal.prototype.createConfirm = function() {
  var self = this;
  this.opts.message = typeof this.opts.message === "string" ? this.opts.message : "";

  this.el = domify("<div id=\"modal-mail-sent\" class=\"reveal-modal\" data-reveal aria-labelledby=\"modalTitle\" aria-hidden=\"true\" role=\"dialog\">\n  <div class=\"row\">\n    <div class=\"small-12 medium-7 large-8 columns\">\n      <h2 id=\"message\"></h2>\n    </div> \n    <div class=\"small-11 medium-4 large-3 columns end\">\n      <ul class=\"button-group even-2\">\n        <li><a href=\"#\" class=\"button btn-confirm-no\">No</a></li> \n        <li><a href=\"#\" class=\"button btn-confirm-yes\">Yes</a></li> \n      </ul>\n    </div> \n  </div>\n  <a class=\"close-reveal-modal\" aria-label=\"Close\">&#215;</a>\n</div>\n");
  $(this.el).find("#message").html(this.opts.message);

  $("body").append(this.el);

  $(".btn-confirm-no").on("click", function(e) {
    e.preventDefault();
    self.close();
  });
  $(".btn-confirm-yes").on("click", function(e) {
    e.preventDefault();
    self.close();
    if (typeof self.opts.cb !== "undefined") {
      self.opts.cb();
    }
  });
};

// Create modal from alert template and append it to body
Modal.prototype.createAlert = function() {
  this.opts.message = typeof this.opts.message === "string" ? this.opts.message : "";

  this.el = domify("<div id=\"modal-alert\" class=\"reveal-modal\" data-reveal aria-labelledby=\"modalTitle\" aria-hidden=\"true\" role=\"dialog\">\n  <div class=\"row\">\n    <div class=\"small-12 columns\">\n      <h2 id=\"message\"></h2>\n    </div> \n  </div>\n  <a class=\"close-reveal-modal\" aria-label=\"Close\">&#215;</a>\n</div>\n");
  $(this.el).find("#message").html(this.opts.message);

  $("body").append(this.el);
};

// Create modal from qfactor_calculation_start template and append it to body
Modal.prototype.createQfCalcStart = function() {
  this.el = domify("<div id=\"modal-mail-sent\" class=\"reveal-modal\" data-reveal aria-labelledby=\"modalTitle\" aria-hidden=\"true\" role=\"dialog\">\n  <h2>Your calculation was submitted.</h2>\n  <p>\n  Calculation usually takes about a few minutes or more depending on your input and our current calculation server workload state.\n  <br />\n  You will receive an e-mail informing you that calculation is complete.\n  </p>\n  <p>\n  Calculation progress will be displayed on the webpage.<br />\n  In the meantime check out our work at <a href=\"http://www.smartengineering.si\" target=\"_blank\">www.smartengineering.si</a>\n  </p>\n  <div class=\"text-center\">\n  You can close this window.\n  </div>\n  <a class=\"close-reveal-modal\" aria-label=\"Close\">&#215;</a>\n</div>\n");
  $("body").append(this.el);
};

// Create modal from qfactor_calculation_complete template and append it to body
Modal.prototype.createQfCalcComplete = function() {
  this.opts.urlResults = typeof this.opts.urlResults === "string" ? this.opts.urlResults : "";
  this.opts.urlResult = typeof this.opts.urlResult === "string" ? this.opts.urlResult : "";

  this.el = domify("<div id=\"qf-modal-calc-complete\" class=\"reveal-modal qf-modal-calc-completed\" data-reveal aria-labelledby=\"modalTitle\" aria-hidden=\"true\" role=\"dialog\">\n  <h2>Your calculation is complete.</h2>\n  <p>To manage your completed calculations click here: <a id=\"link-results\"></a></p>\n  <p>To go directly to results of completed calculation click here: <a id=\"link-result\"></a></p>\n  <a class=\"close-reveal-modal\" aria-label=\"Close\">&#215;</a>\n</div>\n");
  $(this.el).find("#link-results").attr("href", this.opts.urlResults);
  $(this.el).find("#link-results").text(this.opts.urlResults);
  $(this.el).find("#link-result").attr("href", this.opts.urlResult);
  $(this.el).find("#link-result").text(this.opts.urlResult);

  $("body").append(this.el);
};

// Create modal from cgm template and append it to body
Modal.prototype.createCgmCalcStart = function() {
  this.el = domify("<div id=\"cgm-modal-calc-start\" class=\"reveal-modal cgm-modal-calc\" data-reveal aria-labelledby=\"modalTitle\" aria-hidden=\"true\" role=\"dialog\">\n  <h2>Your calculation was submitted</h2>\n  <p>\n  Calculation usually takes about a few minutes or more depending on your input and our current calculation server workload state.\n  <br />\n  You will receive an e-mail informing you that calculation is complete.\n  </p>\n  <p>\n  Calculation progress is displayed on the webpage.<br />\n  In the meantime check out our work at <a href=\"http://www.smartengineering.si\" target=\"_blank\">www.smartengineering.si</a>\n  </p>\n  <div class=\"text-center\">\n  You can close this window.\n  </div>\n  <a class=\"close-reveal-modal\" aria-label=\"Close\">&#215;</a>\n</div>\n");
  $("body").append(this.el);
};

// Create modal from cgm template and append it to body
Modal.prototype.createCgmCalcComplete = function() {
  this.opts.urlResults = typeof this.opts.urlResults === "string" ? this.opts.urlResults : "";
  this.opts.urlResult = typeof this.opts.urlResult === "string" ? this.opts.urlResult : "";

  this.el = domify("<div id=\"cgm-modal-calc-complete\" class=\"reveal-modal cgm-modal-calc\" data-reveal aria-labelledby=\"modalTitle\" aria-hidden=\"true\" role=\"dialog\">\n  <h2>Your calculation is complete.</h2>\n  <p>To manage your completed calculations <a id=\"link-results\">click here</a>.</p>\n  <p>To go directly to results of completed calculation <a id=\"link-result\">click here</a>.</p>\n  <a class=\"close-reveal-modal\" aria-label=\"Close\">&#215;</a>\n</div>\n");
  $(this.el).find("#link-results").attr("href", this.opts.urlResults);
  // $(this.el).find("#link-results").text(this.opts.urlResults);
  $(this.el).find("#link-result").attr("href", this.opts.urlResult);
  // $(this.el).find("#link-result").text(this.opts.urlResult);

  $("body").append(this.el);
};

// Create modal from cgm template and append it to body
Modal.prototype.createCgmDownloadGm = function() {
  var self = this;

  this.el = domify("<div id=\"cgm-modal-download-gm\" class=\"reveal-modal cgm-modal-download\" data-reveal aria-labelledby=\"modalTitle\" aria-hidden=\"true\" role=\"dialog\">\n  <h2>Select ground motions to download.</h2>\n  <p><span>Download characteristic ground motions: </span><a id=\"link-cgm\" class=\"button download download-link\" data-what=\"cgm\">&zwnj;</a></p>\n  <p><span>Download all selected ground motions: </span><a id=\"link-allgm\" class=\"button download download-link\" data-what=\"allgm\">&zwnj;</a></p>\n  <a class=\"close-reveal-modal\" aria-label=\"Close\">&#215;</a>\n</div>\n");
  $("body").append(this.el);

  $(".download-link").on("click", function(e) {
    e.preventDefault();
    self.close();
  });
};

// Create modal from cgm template and append it to body
Modal.prototype.createCgmDownloadData = function() {
  var self = this;

  this.el = domify("<div id=\"cgm-modal-download-data\" class=\"reveal-modal cgm-modal-download\" data-reveal aria-labelledby=\"modalTitle\" aria-hidden=\"true\" role=\"dialog\">\n  <h2>Select spreadsheet format of downloaded data.</h2>\n  <p><span>Microsoft Excel 2007+ spreadsheet (.xlsx): </span><a id=\"link-xlsx\" class=\"button download download-link\" data-what=\"spreadsheet-xlsx\">&zwnj;</a></p>\n  <p><span>Microsoft Excel 2003- spreadsheet (.xls): </span><a id=\"link-xls\" class=\"button download download-link\" data-what=\"spreadsheet-xls\">&zwnj;</a></p>\n  <p><span>OpenDocument spreadsheet (.ods): </span><a id=\"link-ods\" class=\"button download download-link\" data-what=\"spreadsheet-ods\">&zwnj;</a></p>\n  <a class=\"close-reveal-modal\" aria-label=\"Close\">&#215;</a>\n</div>\n");
  $("body").append(this.el);

  $(this.el).on("click", ".download", function(e) {
    e.preventDefault();
    self.close();
  });
};

// Create modal from bulk_mail_sent template and append it to body
Modal.prototype.createBulkMailSent = function() {
  this.el = domify("<div id=\"modal-mail-sent\" class=\"reveal-modal\" data-reveal aria-labelledby=\"modalTitle\" aria-hidden=\"true\" role=\"dialog\">\n  <h2>Great. We are sending your e-mail messages now...</h2>\n  <p>E-mails are being sent in the backround. To check if e-mails were send successfully, go to your e-mail clinet and check your 'Sent' folder.</p>\n  <p>Process of sending e-mail messages can take some time, so if you are sending e-mails to many users, please be patient.</p>\n  <a class=\"close-reveal-modal\" aria-label=\"Close\">&#215;</a>\n</div>\n");
  $("body").append(this.el);
};

// Render(show) modal window created from template
Modal.prototype.render = function() {
  var self = this;
  if (typeof this.el === "undefined") { return; }
  $(this.el).foundation("reveal", "open", {"animation_speed": 200});
  $(this.el).bind("closed.fndtn.reveal", "[data-reveal]", function() {
    self.remove();
  });
};

// Close modal window (modal.remove method is bound to closing event)
Modal.prototype.close = function() {
  $(this.el).foundation("reveal", "close");
};

// Remove modal window from DOM
Modal.prototype.remove = function() {
  this.el.parentNode.removeChild(this.el);
};

module.exports = Modal;

