"use strict";

// Constructor
function ajax() {
  this.ajaxCall = 0;
}

ajax.prototype.sendPost = function(url, data, cb, delay) {
  if (typeof delay === "undefined") { delay = 0; }
  try {
    clearTimeout(this.ajaxCall);
  } catch (e) {}

  function onStartAjaxRequest() {
    // e.g. show spinner
  }
  function onEndAjaxRequest() {
    // e.g. hide spinner
  }

  this.ajaxCall = setTimeout(function() {
    $.ajax({
      "url": url,
      "type": "POST",
      "data": data,
      "error": function(XMLHttpRequest, textStatus, errorThrown) {
        if (typeof cb !== "undefined" && typeof cb.failure !== "undefined") {
          cb.failure(XMLHttpRequest, textStatus, errorThrown);
        }
      },
      "success": function(data, textStatus) {
        if (typeof cb !== "undefined" && typeof cb.success !== "undefined") {
          cb.success(data, textStatus);
        }
      },
      "complete": function() {
        onEndAjaxRequest();
      }
    });
  }, delay);
  onStartAjaxRequest();
  return this.ajaxCall;
};

module.exports = ajax;

