"use strict";


var domify = require("domify");

function Loader(opts) {
  if (!(this instanceof Loader)) { return new Loader(opts); }

  this.opts = opts || {};
  this.opts.rendered = false;
  this.opts.message = typeof this.opts.message === "string" ? this.opts.message : "";
  this.opts.selector = typeof this.opts.selector === "string" ? this.opts.selector : "document";

  this.el = domify("<div class=\"loader\">\n  <table cellpadding=0 cellspacing=0 style=\"margin: 0; border: 0;\"> \n    <tr>\n      <td style=\"padding: 0\">\n        <div class=\"spinner\">\n          <div class=\"rect1\"></div>\n          <div class=\"rect2\"></div>\n          <div class=\"rect3\"></div>\n          <div class=\"rect4\"></div>\n          <div class=\"rect5\"></div>\n          <div class=\"rect6\"></div>\n          <div class=\"rect7\"></div>\n          <div class=\"rect8\"></div>\n          <div class=\"rect9\"></div>\n        </div>\n      </td> \n      <td style=\"padding: 0\">\n        <div class=\"message\"></div>\n      </td> \n    </tr> \n  </table>\n</div>\n");

  this.updateMessage();
}

Loader.prototype.updateMessage = function(message) {
  message = typeof message === "undefined" ? this.opts.message : message;
  $(this.el).find(".message").html(message);
};

Loader.prototype.render = function() {
  if (this.opts.rendered === true) { return; }
  this.opts.rendered = true;
  $(this.opts.selector).html(this.el);
};

Loader.prototype.remove = function() {
  if (this.opts.rendered === false) { return; }
  this.opts.rendered = false;
  try {
    this.el.parentNode.removeChild(this.el);
  } catch (e) {
    console.log(e);
  }
};

module.exports = Loader;

