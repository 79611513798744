"use strict";

var common = function() {
  this.isJSON = function(s) {
    try {
      JSON.parse(s);
    } catch (e) {
      return false;
    }
    return true;
  };

  this.isJSONorUND = function(s) {
    if (typeof s === "undefined") {
      return false;
    }
    return common.isJSON;
  };

  this.getJSONvalue = function(jsonData, jsonKey) {
    var self = this;
    var result = false;
    if (typeof jsonData === "object" && !(jsonData instanceof Array) && jsonData !== null) {
      var keys = Object.keys(jsonData);
      for (var i = 0; i < keys.length; i++) {
        if (keys[i] === jsonKey) {
          return jsonData[keys[i]];
        } else if (typeof jsonData[keys[i]] === "object" && !(jsonData[keys[i]] instanceof Array) && jsonData[keys[i]] !== null) {
          var tmp = self.getJSONvalue(jsonData[keys[i]], jsonKey);
          if (tmp !== false) {
            result = tmp;
          }
        }
      }
    }
    return result;
  };

  this.isInt = function(value) {
    var x;
    if (isNaN(value)) {
      return false;
    }
    x = parseFloat(value);
    return (x | 0) === x;
  };

  this.giveMeGUID = function() {
    var guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === "x" ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
    return guid;
  };

  this.selectText = function(jquerySelection) {
    var doc = document
      , text = jquerySelection
      , range, selection;

    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  this.pageLeaveWarning = function(warning) {
    if (typeof warning === "undefined") { warning = "Leave this page?"; }
    $(window).bind("beforeunload", function() {
      return warning;
    });
  };

};

common.instance = null;
common.getInstance = function() {
  if (this.instance === null) {
    this.instance = new common();
  }
  return this.instance;
};

module.exports = common.getInstance();

