"use strict";

var config = function() {
  this.paths = {};
  this.paths.server = "http://localhost/";
  this.paths.server_qfactor = this.paths.server + "qfactor/";
  this.paths.server_cgm = this.paths.server + "cgm/";
  this.paths.server_users = this.paths.server + "users/";

  this.paths.public = this.paths.server + "#environment#";
  this.paths.public_img_cgm = this.paths.public + "cgm/img/";
};

config.instance = null;
config.getInstance = function() {
  if (this.instance === null) {
    this.instance = new config();
  }
  return this.instance;
};

module.exports = config.getInstance();

